<template>
  <div class="flex flex-col">

    <!-- Answer Information -->
    <div v-if="providedAnswer" class="flex flex-col">

      <!-- User Name -->
      <div v-if="userName" class="_questionnaire-info-point">
        <label>
          {{$t(user)}}
        </label>
        <label>
          {{ userName }}
        </label>
      </div>

      <!-- User Email -->
      <div v-if="userEmail" class="_questionnaire-info-point">
        <label>
          {{$t(email)}}
        </label>
        <label>
          {{ userEmail }}
        </label>
      </div>

      <!-- Answer start at and finish at  -->
      <div v-if="answerStartAt && answerFinishedAt" class="_questionnaire-info-point">
        <label>
          Iniciou às
        </label>
        <label>
          {{ answerStartAt }}
        </label>
        <label class="font-bold">
          e finalizou às
        </label>
        <label>
          {{ answerFinishedAt }}
        </label>
      </div>

    </div>

    <vs-divider v-if="providedAnswer" class="w-full my-2"/>

    <!-- Title/Name -->
    <div v-if="name" class="_questionnaire-info-point flex flex-col sm:flex-row sm:gap-2">
      <label>
        {{ $t('entity_id') }}:
      </label>
      <label>
        {{ questionnaireId }}
      </label> -
      <label>
        {{ name }}
      </label>
    </div>
    <div v-if="name" class="_questionnaire-info-point">
      <label>
        {{ $t('link') }}:
      </label>
      <label>
        {{ linkQuestionnaire }}
      </label>
      <div>
        <vs-button
            type="flat"
            icon="content_copy"
            class="footer-button"
            v-clipboard:copy="linkQuestionnaire"
            v-clipboard:success="onSucceddCopy"
            v-clipboard:error="onErrorCopy">{{ $t('copiar') }}</vs-button>
      </div>
    </div>

    <!-- Period -->
    <div v-if="startAt && finishAt" class="_questionnaire-info-point">
      <label>
        Período:
      </label>
      <label>
        {{ startAt }}
      </label>
      <label class="font-bold">
        a
      </label>
      <label>
        {{ finishAt }}
      </label>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    questionnaire: {
      type: Object,
      default: null
    },
    answer: {
      type: Object,
      default: null
    }
  },

  computed: {
    providedAnswer() {
      return this.answer !== null
    },
    userName() {
      return this._.get(this.answer, 'user.name', null)
    },
    userEmail() {
      return this._.get(this.answer, 'user.email', null)
    },
    answerStartAt() {
      const createdAtRaw = this._.get(this.answer, 'created_at', null)
      return createdAtRaw
    },
    answerFinishedAt() {
      const finishedAtRaw = this._.get(this.answer, 'finished_at_formatted', null)
      return finishedAtRaw
    },
    questionnaireId() {
      return this._.get(this.questionnaire, 'id', null)
    },
    name() {
      return this._.get(this.questionnaire, 'name', null) || this._.get(this.questionnaire, 'title', null)
    },
    startAt() {
      return this._.get(this.questionnaire, 'start_at_formatted', null)
    },
    createdAt() {
      return this._.get(this.questionnaire, 'created_at_formatted', null)
    },
    finishAt() {
      return this._.get(this.questionnaire, 'finish_at_formatted', null)
    },
    linkQuestionnaire() {
      return this._.get(this.questionnaire, 'short_url', null)
    }
  },
  methods: {
    onSucceddCopy() {
      this.notifySuccess(this.$vs, `${this.$t('copiado')}!`, 2000, 'center-top')
    },
  }
}
</script>

<style lang="scss" scoped>
._questionnaire-info-point {
  @apply flex;
  @apply flex-col;

  & > :nth-child(1) {
    @apply font-bold;
  }

  @screen sm {
    @apply flex-row;
    @apply gap-2;
  }
}
</style>
