var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex flex-col" },
    [
      _vm.providedAnswer
        ? _c("div", { staticClass: "flex flex-col" }, [
            _vm.userName
              ? _c("div", { staticClass: "_questionnaire-info-point" }, [
                  _c("label", [_vm._v(" " + _vm._s(_vm.$t(_vm.user)) + " ")]),
                  _c("label", [_vm._v(" " + _vm._s(_vm.userName) + " ")]),
                ])
              : _vm._e(),
            _vm.userEmail
              ? _c("div", { staticClass: "_questionnaire-info-point" }, [
                  _c("label", [_vm._v(" " + _vm._s(_vm.$t(_vm.email)) + " ")]),
                  _c("label", [_vm._v(" " + _vm._s(_vm.userEmail) + " ")]),
                ])
              : _vm._e(),
            _vm.answerStartAt && _vm.answerFinishedAt
              ? _c("div", { staticClass: "_questionnaire-info-point" }, [
                  _c("label", [_vm._v(" Iniciou às ")]),
                  _c("label", [_vm._v(" " + _vm._s(_vm.answerStartAt) + " ")]),
                  _c("label", { staticClass: "font-bold" }, [
                    _vm._v(" e finalizou às "),
                  ]),
                  _c("label", [
                    _vm._v(" " + _vm._s(_vm.answerFinishedAt) + " "),
                  ]),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _vm.providedAnswer
        ? _c("vs-divider", { staticClass: "w-full my-2" })
        : _vm._e(),
      _vm.name
        ? _c(
            "div",
            {
              staticClass:
                "_questionnaire-info-point flex flex-col sm:flex-row sm:gap-2",
            },
            [
              _c("label", [_vm._v(" " + _vm._s(_vm.$t("entity_id")) + ": ")]),
              _c("label", [_vm._v(" " + _vm._s(_vm.questionnaireId) + " ")]),
              _vm._v(" - "),
              _c("label", [_vm._v(" " + _vm._s(_vm.name) + " ")]),
            ]
          )
        : _vm._e(),
      _vm.name
        ? _c("div", { staticClass: "_questionnaire-info-point" }, [
            _c("label", [_vm._v(" " + _vm._s(_vm.$t("link")) + ": ")]),
            _c("label", [_vm._v(" " + _vm._s(_vm.linkQuestionnaire) + " ")]),
            _c(
              "div",
              [
                _c(
                  "vs-button",
                  {
                    directives: [
                      {
                        name: "clipboard",
                        rawName: "v-clipboard:copy",
                        value: _vm.linkQuestionnaire,
                        expression: "linkQuestionnaire",
                        arg: "copy",
                      },
                      {
                        name: "clipboard",
                        rawName: "v-clipboard:success",
                        value: _vm.onSucceddCopy,
                        expression: "onSucceddCopy",
                        arg: "success",
                      },
                      {
                        name: "clipboard",
                        rawName: "v-clipboard:error",
                        value: _vm.onErrorCopy,
                        expression: "onErrorCopy",
                        arg: "error",
                      },
                    ],
                    staticClass: "footer-button",
                    attrs: { type: "flat", icon: "content_copy" },
                  },
                  [_vm._v(_vm._s(_vm.$t("copiar")))]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.startAt && _vm.finishAt
        ? _c("div", { staticClass: "_questionnaire-info-point" }, [
            _c("label", [_vm._v(" Período: ")]),
            _c("label", [_vm._v(" " + _vm._s(_vm.startAt) + " ")]),
            _c("label", { staticClass: "font-bold" }, [_vm._v(" a ")]),
            _c("label", [_vm._v(" " + _vm._s(_vm.finishAt) + " ")]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }